import React, { useState } from "react";
import ModalBasic from "../actions/ModalBasic";
import { baseAPI } from "../../config";
import axios from "axios";

function UpdateDebtor(props) {
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const [debtorId] = useState(props.idDebtor);
  const [debtorName, setDebtorName] = useState("");
  const [debtorAddress, setDebtorAddress] = useState("");
  const [debtorcity, setDebtorCity] = useState("");
  const [debtorzip, setDebtorZip] = useState("");
  const [debtorPhone, setDebtorPhone] = useState("");
  const [debtorEmail, setDebtorEmail] = useState("");

  const getDataDebtor = async () => {
    try {
      const response = await axios.get(`${baseAPI}/debtors/debtor/${debtorId}`);
      setDebtorName(response.data.debtorname);
      setDebtorAddress(response.data.debtoradress);
      setDebtorCity(response.data.debtorcity);
      setDebtorZip(response.data.debtorzip);
      setDebtorPhone(response.data.debtorphone);
      setDebtorEmail(response.data.debtormail);
    } catch (error) {
      console.log(error);
    }
  };

  const onChangeName = (e) => {
    setDebtorName(e.target.value);
  };

  const onChangeAddress = (e) => {
    setDebtorAddress(e.target.value);
  };

  const onChangeCity = (e) => {
    setDebtorCity(e.target.value);
  };

  const onChangeZip = (e) => {
    setDebtorZip(e.target.value);
  };

  const onChangePhone = (e) => {
    setDebtorPhone(e.target.value);
  };

  const onChangeEmail = (e) => {
    setDebtorEmail(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const deudorData = {
      debtorname: debtorName,
      debtoradress: debtorAddress,
      debtorphone: debtorPhone,
      debtormail: debtorEmail,
      debtorcity: debtorcity,
      debtorzip: debtorzip,
    };
    async function updateDebtor(deudorData) {
      const response = await axios.post(
        `${baseAPI}/debtors/update/debtor/${debtorId}`,
        deudorData
      );
      return response;
    }
    updateDebtor(deudorData).then((response) => {
      if (response.status === 200) {
        window.location.reload();
      }
    });
  };

  return (
    <div className="">
      <button
        className="block flex-1 text-center text-sm text-slate-600 hover:text-slate-800 font-medium px-3 py-4 group"
        aria-controls="feedback-modal"
        onClick={(e) => {
          e.stopPropagation();
          setFeedbackModalOpen(true);
          getDataDebtor();
        }}
      >
        <div className="flex items-center justify-center">
          <svg
            className="w-4 h-4 fill-current text-slate-400 group-hover:text-slate-500 shrink-0 mr-2"
            viewBox="0 0 16 16"
          >
            <path d="M11.7.3c-.4-.4-1-.4-1.4 0l-10 10c-.2.2-.3.4-.3.7v4c0 .6.4 1 1 1h4c.3 0 .5-.1.7-.3l10-10c.4-.4.4-1 0-1.4l-4-4zM4.6 14H2v-2.6l6-6L10.6 8l-6 6zM12 6.6L9.4 4 11 2.4 13.6 5 12 6.6z" />
          </svg>
          <span>Editar Deudor</span>
        </div>
      </button>
      <ModalBasic
        id="feedback-modal"
        modalOpen={feedbackModalOpen}
        setModalOpen={setFeedbackModalOpen}
        title="Editar datos deudor"
      >
        {/* Modal content */}
        <section className="px-5 py-4 ">
          <div className="container mt-5 ">
            <form
              className="flex flex-col flex-wrap "
              method="POST"
              action="#"
              onSubmit={onSubmit}
            >
              <h2 className="block text-left pb-3 text-blue-500 whitespace-nowrap border-b-2 border-blue-500">
                Datos del deudor
              </h2>

              <div className="flex flex-wrap -mx-3 mb-6 mt-5">
                <div className="w-full md:w-1/2 px-3">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1 mt-2"
                    htmlFor="grid-last-name"
                  >
                    Nombre
                  </label>
                  <input
                    className=" rounded w-full text-gray-700 focus:outline-none border-b-1 border-gray-300 focus:border-blue-600 transition duration-500 px-3 pb-3"
                    id="grid-last-name"
                    type="text"
                    placeholder="Nombre"
                    onChange={onChangeName}
                    required={true}
                    value={debtorName}
                  />
                </div>
                <div className="w-full md:w-1/2 px-3">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1 mt-2"
                    htmlFor="grid-last-name"
                  >
                    Email
                  </label>
                  <input
                    className=" rounded w-full text-gray-700 focus:outline-none border-b-1 border-gray-300 focus:border-blue-600 transition duration-500 px-3 pb-3"
                    id="grid-last-name"
                    type="text"
                    placeholder="Email"
                    onChange={onChangeEmail}
                    required={true}
                    value={debtorEmail}
                  />
                </div>
                <div className="w-full md:w-1/2 px-3">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1 mt-2"
                    htmlFor="grid-last-name"
                  >
                    Telefono
                  </label>
                  <input
                    className=" rounded w-full text-gray-700 focus:outline-none border-b-1 border-gray-300 focus:border-blue-600 transition duration-500 px-3 pb-3"
                    id="grid-last-name"
                    type="text"
                    placeholder="Telefono"
                    onChange={onChangePhone}
                    required={true}
                    value={debtorPhone}
                  />
                </div>
                <div className="w-full md:w-1/2 px-3">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1 mt-2"
                    htmlFor="grid-last-name"
                  >
                    Calle
                  </label>
                  <input
                    className=" rounded w-full text-gray-700 focus:outline-none border-b-1 border-gray-300 focus:border-blue-600 transition duration-500 px-3 pb-3"
                    id="grid-last-name"
                    type="text"
                    placeholder="Calle"
                    onChange={onChangeAddress}
                    required={true}
                    value={debtorAddress}
                  />
                </div>
                <div className="w-full md:w-1/2 px-3">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1 mt-2"
                    htmlFor="grid-last-name"
                  >
                    Ciudad
                  </label>
                  <input
                    className=" rounded w-full text-gray-700 focus:outline-none border-b-1 border-gray-300 focus:border-blue-600 transition duration-500 px-3 pb-3"
                    id="grid-last-name"
                    type="text"
                    placeholder="Ciudad"
                    onChange={onChangeCity}
                    required={true}
                    value={debtorcity}
                  />
                </div>
                <div className="w-full md:w-1/2 px-3">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1 mt-2"
                    htmlFor="grid-last-name"
                  >
                    Codigo Postal
                  </label>
                  <input
                    className=" rounded w-full text-gray-700 focus:outline-none border-b-1 border-gray-300 focus:border-blue-600 transition duration-500 px-3 pb-3"
                    id="grid-last-name"
                    type="text"
                    placeholder="Codigo Postal"
                    onChange={onChangeZip}
                    required={true}
                    value={debtorzip}
                  />
                </div>
              </div>

              <button
                className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 rounded shadow-lg hover:shadow-xl transition duration-200"
                type="submit"
              >
                Actualizar datos deudor
              </button>
            </form>
          </div>
        </section>
      </ModalBasic>
    </div>
  );
}

export default UpdateDebtor;
