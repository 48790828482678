import { React, useState } from "react";
import { Paragraph, Document, Packer, AlignmentType, TextRun } from "docx";
import { saveAs } from "file-saver";

function CreateBurofax(props) {

  const [debtor] = useState(props.debtor);
  const [expedient] = useState(props.expedient);
  const [dateToday] = useState(new Date().toLocaleDateString());

  const createDocument = () => {
    const doc = new Document({
      sections: [
        {
          properties: {},
          children: [
            new Paragraph({
              text: debtor.debtorname,
              alignment: AlignmentType.RIGHT,
            }),
            new Paragraph({
              text: debtor.debtoradress + ", " + debtor.debtorcity + ", " + debtor.debtorzip,
              alignment: AlignmentType.RIGHT,
              spacing: {
                after: 1000,
              },
            }),
            new Paragraph({
              text: `En Barcelona a ${dateToday},`,
              alignment: AlignmentType.JUSTIFIED,
              spacing: {
                after: 1000,
              },
              indent: {
                left: 720,
                right: 720,
              },
            }),
            new Paragraph({
              text: `Muy señor/a mío:`,
              alignment: AlignmentType.JUSTIFIED,
              spacing: {
                after: 800,
              },
              indent: {
                left: 720,
                right: 720,
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: `Me dirijo a usted en calidad de abogado de mi cliente, `,
                  alignment: AlignmentType.JUSTIFIED,
                }),
                new TextRun({
                  text: `${expedient.user_id.username} ${expedient.user_id.userlastname}`,
                  bold: true,
                  alignment: AlignmentType.JUSTIFIED,
                }),
                new TextRun({
                  text: `, para informarle que hemos sido encargados de la reclamación de la deuda que usted mantiene con mi cliente. Según la documentación que obra en nuestro poder, el saldo pendiente es de ${expedient.importe_deuda} €.`,
                  alignment: AlignmentType.JUSTIFIED,
                }),
              ],
              spacing: {
                after: 800,
              },
              indent: {
                left: 720,
                right: 720,
              },
            }),            
            new Paragraph({
              children: [
                new TextRun({
                  text: `Por tanto, le requiero formalmente que `,
                  alignment: AlignmentType.JUSTIFIED,
                }),
                new TextRun({
                  text: `PROCEDA AL PAGO`,
                  bold: true,
                  alignment: AlignmentType.JUSTIFIED,
                }),
                new TextRun({
                  text: ` de dicha cantidad en el plazo de 10 días hábiles a contar desde la recepción de la presente`,
                  alignment: AlignmentType.JUSTIFIED,
                }),
              ],
              spacing: {
                after: 800,
              },
              indent: {
                left: 720,
                right: 720,
              },
            }),            
            new Paragraph({
              text: `No obstante, en caso de estar usted interesado en alcanzar un acuerdo amistoso de pago, quedo a la espera de recibir su propuesta, en los siete días naturales siguientes a contar desde la recepción de esta comunicación. Transcurrido el plazo sin haber tenido noticias suyas, le informamos que interpondremos las acciones judiciales pertinentes.`,
              alignment: AlignmentType.JUSTIFIED,
              spacing: {
                after: 800,
              },
              indent: {
                left: 720,
                right: 720,
              },
            }),
            new Paragraph({
              text: `Atentamente,`,
              alignment: AlignmentType.JUSTIFIED,
              spacing: {
                after: 1200,
              },
              indent: {
                left: 720,
                right: 720,
              },
            }),
            new Paragraph({
              text: `Marc Castells Roca,`,
              alignment: AlignmentType.JUSTIFIED,
              indent: {
                left: 720,
                right: 720,
              },
            }),
            new Paragraph({
              text: `Abogado,`,
              alignment: AlignmentType.JUSTIFIED,
              indent: {
                left: 720,
                right: 720,
              },
            }),
            new Paragraph({
              text: `661 210 451`,
              alignment: AlignmentType.JUSTIFIED,
              indent: {
                left: 720,
                right: 720,
              },
            }),
          ],
        },
      ],
    });

    Packer.toBlob(doc).then((blob) => {
      console.log(blob);
      saveAs(blob, "example.docx");
      console.log("Document created successfully");
    });
  };

  return (
    <button
      className="block flex-1 text-center text-sm text-blue-500 hover:text-blue-600 font-medium px-3 py-4"
      onClick={createDocument}
    >
      <div className="flex items-center justify-center">
        <svg className="w-4 h-4 fill-current shrink-0 mr-2" viewBox="0 0 24 24">
          <path d="M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm2 16H8v-2h8v2zm0-4H8v-2h8v2zm-3-5V3.5L18.5 9H13z"></path>
        </svg>
        <span>Burofax</span>
      </div>
    </button>
  );
}

export default CreateBurofax;
