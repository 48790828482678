import React, { useState, useEffect } from "react";
import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import axios from "axios";
import Notes from "../partials/components/Notes";
import FilesList from "../partials/components/Files-list";
import UploadFiles from "../services/uploadfiles";
import MoneyComeBack from "../partials/components/MoneyComeBack";
import Debtors from "../partials/components/Debtors";
import UpdateExpedient from "../partials/components/UpdateExpedient";
import InteractionsExpedient from "../partials/components/Interactions";
import CurrencyFormat from "react-currency-format";
import { baseAPI } from "../config";
import jwt_decode from "jwt-decode";

function ExpedientView(props) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [deudor, setDeudor] = useState("");
  const [deudorNif, setDeudorNif] = useState("");
  const [importe_deuda, setImporte_deuda] = useState("");
  const [expedientId] = useState(props.match.params.id);
  const [userRole, setUserRole] = useState("");
  const [estado, setEstado] = useState("");
  const [viewNotes, setViewNotes] = useState(false);
  const [viewDocuments, setViewDocuments] = useState(false);
  const [viewDebt, setViewDebt] = useState(false);
  const [viewDebtors, setViewDebtors] = useState(false);
  const [viewExpedientData, setViewExpedientData] = useState(false);
  const [viewInteractions, setViewInteractions] = useState(false);

  useEffect(() => {
    const userToken = localStorage.getItem("token");
    const decoded = jwt_decode(userToken);
    setUserRole(decoded.role);
    let localStorageViewNotes = false;
    let localStorageViewDocuments = false;
    let localStorageViewDebt = false;
    let localStorageViewDebtors = false;
    let localStorageViewExpedientData = false;
    let localStorageViewInteractions = false;
    if (localStorage.getItem("viewNotes") === "true") {
      localStorageViewNotes = true;
    }
    if (localStorage.getItem("viewDocuments") === "true") {
      localStorageViewDocuments = true;
    }
    if (localStorage.getItem("viewDebt") === "true") {
      localStorageViewDebt = true;
    }
    if (localStorage.getItem("viewDebtors") === "true") {
      localStorageViewDebtors = true;
    }
    if (localStorage.getItem("viewExpedientData") === "true") {
      localStorageViewExpedientData = true;
    }
    if (localStorage.getItem("viewNotes") === null) {
      localStorageViewNotes = true;
    }
    if (localStorage.getItem("viewInteractions") === "true") {
      localStorageViewInteractions = true;
    }

    axios
      .get(`${baseAPI}/expedients/` + expedientId)
      .then((response) => {
        setDeudor(response.data.deudores_id[0].debtorname);
        setDeudorNif(response.data.deudores_id[0].debtornif);
        setImporte_deuda(response.data.importe_deuda);
        setEstado(response.data.estado);
        setViewNotes(localStorageViewNotes);
        setViewDocuments(localStorageViewDocuments);
        setViewDebt(localStorageViewDebt);
        setViewDebtors(localStorageViewDebtors);
        setViewExpedientData(localStorageViewExpedientData);
        setViewInteractions(localStorageViewInteractions);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [expedientId]);

  // Función para cambiar el estado de ViewNotes on click

  const changeViewNotes = () => {
    setViewNotes(true);
    setViewDocuments(false);
    setViewDebt(false);
    setViewDebtors(false);
    setViewExpedientData(false);
    setViewInteractions(false);

    // save state in local storage
    localStorage.setItem("viewNotes", true);
    localStorage.setItem("viewDocuments", false);
    localStorage.setItem("viewDebt", false);
    localStorage.setItem("viewDebtors", false);
    localStorage.setItem("viewExpedientData", false);
    localStorage.setItem("viewInteractions", false);
  };

  // Función para cambiar el estado de ViewDocuments on click

  const changeViewDocuments = () => {
    setViewNotes(false);
    setViewDocuments(true);
    setViewDebt(false);
    setViewDebtors(false);
    setViewExpedientData(false);
    setViewInteractions(false);

    // save state in local storage
    localStorage.setItem("viewNotes", false);
    localStorage.setItem("viewDocuments", true);
    localStorage.setItem("viewDebt", false);
    localStorage.setItem("viewDebtors", false);
    localStorage.setItem("viewExpedientData", false);
    localStorage.setItem("viewInteractions", false);
  };

  // Función para cambiar el estado de ViewDebt on click

  const changeViewDebt = () => {
    setViewNotes(false);
    setViewDocuments(false);
    setViewDebt(true);
    setViewDebtors(false);
    setViewExpedientData(false);
    setViewInteractions(false);

    // save state in local storage

    localStorage.setItem("viewNotes", false);
    localStorage.setItem("viewDocuments", false);
    localStorage.setItem("viewDebt", true);
    localStorage.setItem("viewDebtors", false);
    localStorage.setItem("viewExpedientData", false);
    localStorage.setItem("viewInteractions", false);
  };

  // Función para cambiar el estado de ViewDebtors on click

  const changeViewDebtors = () => {
    setViewNotes(false);
    setViewDocuments(false);
    setViewDebt(false);
    setViewDebtors(true);
    setViewExpedientData(false);
    setViewInteractions(false);

    // save state in local storage

    localStorage.setItem("viewNotes", false);
    localStorage.setItem("viewDocuments", false);
    localStorage.setItem("viewDebt", false);
    localStorage.setItem("viewDebtors", true);
    localStorage.setItem("viewExpedientData", false);
    localStorage.setItem("viewInteractions", false);
  };

  // Función para cambiar el estado de ViewExpedientData on click

  const changeViewExpedientData = () => {
    setViewNotes(false);
    setViewDocuments(false);
    setViewDebt(false);
    setViewDebtors(false);
    setViewExpedientData(true);
    setViewInteractions(false);

    // save state in local storage

    localStorage.setItem("viewNotes", false);
    localStorage.setItem("viewDocuments", false);
    localStorage.setItem("viewDebt", false);
    localStorage.setItem("viewDebtors", false);
    localStorage.setItem("viewExpedientData", true);
    localStorage.setItem("viewInteractions", false);
  };

  // Función para cambiar el estado de ViewInteractions on click

  const changeViewInteractions = () => {
    setViewNotes(false);
    setViewDocuments(false);
    setViewDebt(false);
    setViewDebtors(false);
    setViewExpedientData(false);
    setViewInteractions(true);

    // save state in local storage

    localStorage.setItem("viewNotes", false);
    localStorage.setItem("viewDocuments", false);
    localStorage.setItem("viewDebt", false);
    localStorage.setItem("viewDebtors", false);
    localStorage.setItem("viewExpedientData", false);
    localStorage.setItem("viewInteractions", true);
  };

  return (
    <div className="flex h-screen overflow-hidden">
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto ">
            <div className="ml-4  bg-white shadow-lg rounded-sm border border-gray-200">
              <header className="px-5 py-6 bg-gray-50 shadow-sm">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-4 md:gap-y-0">
                  <div>
                    <h2 className="text-lg font-semibold text-gray-800">
                      Expediente:{" "}
                      <span className="ml-2 text-xl font-bold text-blue-600">
                        {expedientId}
                      </span>
                    </h2>
                    <h2 className="text-lg font-semibold text-gray-800">
                      Deudor Principal:{" "} 
                      <span className="ml-2 text-xl font-bold text-blue-600">
                        {deudor} 
                      </span>
                      <span className="ml-2 text-xs text-blue-600">
                        {deudorNif}
                      </span>
                    </h2>
                    <h2 className="text-lg font-semibold text-gray-800">
                      Deuda reclamada:
                      <span className="ml-2 text-xl font-bold text-red-600 inline-block">
                        <CurrencyFormat
                          value={importe_deuda}
                          displayType={"text"}
                          thousandSeparator={"."}
                          decimalSeparator={","}
                          suffix={" €"}
                          decimalScale={2}
                          renderText={(value) => <>{value}</>}
                        />
                      </span>
                    </h2>
                  </div>
                  <div className="flex md:justify-end items-center">
                    <span className="text-xl font-bold text-blue-600 bg-blue-100 rounded-full px-4 py-2 shadow">
                      {estado}
                    </span>
                  </div>
                </div>
              </header>

              <section className="">
                <div className="lg:p-10 md:p-16 sm:p-8 min-w-0 w-full flex-auto lg:static lg:max-h-full lg:overflow-visible">
                  <div>
                    <div className="min-w-0 flex-auto px-4 sm:px-6 xl:px-8 pt-3 pb-24 lg:pb-16">
                      <div>
                        <div className="relative mb-8">
                          {/* Start */}
                          <ul className="flex flex-wrap -m-1">
                            <li className="m-1">
                              <button
                                className={
                                  viewInteractions === true
                                    ? "inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border border-transparent shadow-sm bg-indigo-500 text-white duration-150 ease-in-out"
                                    : "inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border border-slate-200 hover:border-slate-300 shadow-sm bg-white text-slate-500 duration-150 ease-in-out"
                                }
                                onClick={changeViewInteractions}
                              >
                                Interacciones
                              </button>
                            </li>
                            <li className="m-1">
                              <button
                                className={
                                  viewNotes === true
                                    ? "inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border border-transparent shadow-sm bg-indigo-500 text-white duration-150 ease-in-out"
                                    : "inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border border-slate-200 hover:border-slate-300 shadow-sm bg-white text-slate-500 duration-150 ease-in-out"
                                }
                                onClick={changeViewNotes}
                              >
                                Anotaciones
                              </button>
                            </li>
                            <li className="m-1">
                              <button
                                className={
                                  viewDocuments === true
                                    ? "inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border border-transparent shadow-sm bg-indigo-500 text-white duration-150 ease-in-out"
                                    : "inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border border-slate-200 hover:border-slate-300 shadow-sm bg-white text-slate-500 duration-150 ease-in-out"
                                }
                                onClick={changeViewDocuments}
                              >
                                Documentos
                              </button>
                            </li>
                            <li className="m-1">
                              <button
                                className={
                                  viewDebt === true
                                    ? "inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border border-transparent shadow-sm bg-indigo-500 text-white duration-150 ease-in-out"
                                    : "inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border border-slate-200 hover:border-slate-300 shadow-sm bg-white text-slate-500 duration-150 ease-in-out"
                                }
                                onClick={changeViewDebt}
                              >
                                Deuda
                              </button>
                            </li>
                            <li className="m-1">
                              <button
                                className={
                                  viewDebtors === true
                                    ? "inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border border-transparent shadow-sm bg-indigo-500 text-white duration-150 ease-in-out"
                                    : "inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border border-slate-200 hover:border-slate-300 shadow-sm bg-white text-slate-500 duration-150 ease-in-out"
                                }
                                onClick={changeViewDebtors}
                              >
                                Deudores
                              </button>
                            </li>
                            {userRole === "admin" && (
                              <li className="m-1">
                                <button
                                  className={
                                    viewExpedientData === true
                                      ? "inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border border-transparent shadow-sm bg-indigo-500 text-white duration-150 ease-in-out"
                                      : "inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border border-slate-200 hover:border-slate-300 shadow-sm bg-white text-slate-500 duration-150 ease-in-out"
                                  }
                                  onClick={changeViewExpedientData}
                                >
                                  Datos del expediente
                                </button>
                              </li>
                            )}
                          </ul>
                          {/* End */}
                        </div>
                      </div>
                      {viewNotes === true && (
                        <Notes idExpedient={expedientId} />
                      )}
                      {viewDebt === true && (
                        <MoneyComeBack idExpedient={expedientId} />
                      )}
                      {viewDebtors === true && (
                        <Debtors idExpedient={expedientId} />
                      )}
                      {viewExpedientData === true && userRole === "admin" && (
                        <UpdateExpedient idExpedient={expedientId} />
                      )}
                      {viewDocuments === true && (
                        <FilesList idExpedient={expedientId} />
                      )}
                      {viewDocuments === true && (
                        <UploadFiles idExpedient={expedientId} />
                      )}
                      {viewInteractions === true && (
                        <InteractionsExpedient idExpedient={expedientId} />
                      )}
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default ExpedientView;
