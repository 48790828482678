import React, { useEffect, useState } from "react";
import axios from "axios";
import jwt_decode from "jwt-decode";
import CurrencyFormat from "react-currency-format";
import moment from "moment";
import { baseAPI } from "../../config";
import { toast } from "react-toastify";

const getStatusStyle = (status) => {
  switch (status) {
    case "Preparando reclamación":
      return "bg-yellow-200 text-yellow-900";
    case "Burofax con resultado negativo":
      return "bg-red-200 text-red-900";
    case "Burofax con resultado positivo":
      return "bg-blue-200 text-blue-900";
    case "On going":
      return "bg-green-200 text-green-900";
    case "Demanda presentada":
      return "bg-indigo-200 text-indigo-900";
    case "Demanda Admitida":
      return "bg-purple-200 text-purple-900";
    case "Sentencia Dictada":
      return "bg-green-200 text-green-900";
    case "Hot":
      return "bg-red-300 text-yellow-800";
    case "Cold":
      return "bg-gray-200 text-gray-900";
    case "Cancelled":
      return "bg-red-200 text-white";
    case "Dispute":
      return "bg-pink-200 text-pink-900";
    case "Burofax Enviado":
      return "bg-teal-200 text-teal-900";
    case "Cobrado":
      return "bg-blue-200 text-blue-900";
    case "Payment Intent":
      return "bg-blue-200 text-blue-900";
    case "Email opened":
      return "bg-blue-200 text-blue-900";
    case "bounced":
      return "bg-red-200 text-red-900";
    case "Demanda pendiente":
      return "bg-yellow-200 text-yellow-900";
    case "Demanda preparada":
      return "bg-yellow-200 text-yellow-900";
    default:
      return "bg-purple-200 text-purple-900";
  }
};

const CurrencyFormatComponent = ({ value }) => (
  <CurrencyFormat
    value={value}
    displayType={"text"}
    thousandSeparator={"."}
    decimalSeparator={","}
    suffix={" €"}
    decimalScale={2}
  />
);

const Expedients = ({
  createdAt,
  expedient,
  viewExpedient,
  deleteExpedient,
  role,
}) => {
  const { user_id, deudores_id, importe_deuda, estado, _id } = expedient;
  const formattedDate = createdAt
    ? moment(createdAt).format("DD/MM/YYYY")
    : "No Date";
  return (
    <tr>
      <td className="p-2 whitespace-nowrap">
        <div className="flex items-center">
          <div className="font-medium text-gray-800">{formattedDate}</div>
        </div>
      </td>
      <td className="p-2 whitespace-nowrap">
        <div className="flex items-center">
          <div className="font-medium text-gray-800">
            {user_id.usermail ? (
              user_id.usermail
            ) : (
              <span role="img" aria-label="no mail">
                ❌
              </span>
            )}
          </div>
        </div>
      </td>

      <td className="p-2 whitespace-nowrap">
        <div className="flex items-center">
          <div className="font-medium text-gray-800">
            {deudores_id[0].debtorname}
          </div>
        </div>
      </td>
      <td className="p-2 whitespace-nowrap">
        <div className="text-left font-medium">
          <CurrencyFormatComponent value={importe_deuda} />
        </div>
      </td>
      <td className="p-2 whitespace-nowrap">
        <span
          className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusStyle(
            estado
          )}`}
        >
          {estado}
        </span>
      </td>
      <td className="p-2 whitespace-nowrap flex space-x-2">
        <a href={`/view/${_id}`} target="_blank" rel="noreferrer">
          🔍
        </a>
        {role === "admin" && (
          <button
            aria-label="Eliminar expediente"
            className="focus:outline-none"
            onClick={() => deleteExpedient(_id)}
          >
            🗑️
          </button>
        )}
      </td>
    </tr>
  );
};

const ExpedientsAdmin = ({ searchText }) => {
  const [expedients, setExpedients] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const itemsPerPage = 20;
  const [role, setRole] = useState("");
  const [selectedEstado, setSelectedEstado] = useState("");
  const [selectedCliente, setSelectedCliente] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [sortField] = useState("importe_deuda");
  const [sortOrder, setSortOrder] = useState("desc");

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage); // Esto debería actualizar la página actual
    }
  };

  const toggleSortOrder = () => {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  useEffect(() => {
    const fetchExpedients = async () => {
      // Asegurándose de que el token está disponible
      const userToken = localStorage.getItem("token");
      if (!userToken) {
        console.log("no hay token");
        return;
      }

      // Decodificando el token para obtener la información del usuario
      const decoded = jwt_decode(userToken);
      setRole(decoded.role);

      try {
        const response = await axios.get(`${baseAPI}/expedients`, {
          params: {
            decoded: JSON.stringify(decoded), // Usando 'decoded' aquí
            page: currentPage,
            limit: itemsPerPage,
            search: searchText,
            estado: selectedEstado,
            cliente: selectedCliente,
            sortField,
            sortOrder,
          },
        });
        setExpedients(response.data.expedients);
        setTotalPages(response.data.totalPages);
      } catch (error) {
        console.log(error);
      }
    };

    fetchExpedients();
  }, [
    currentPage,
    searchText,
    selectedEstado,
    selectedCliente,
    itemsPerPage,
    sortField,
    sortOrder,
  ]);

  function Pagination({ currentPage, totalPages, onPageChange }) {
    return (
      <div className="flex justify-center items-center mt-4">
        {/* Botón Anterior */}
        <button
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <span className="sr-only">Previous</span>
          <span className="text-lg">&larr;</span>
        </button>

        {/* Indicador de Página */}
        <span className="mx-2 text-sm text-gray-700">
          {currentPage} / {totalPages}
        </span>

        {/* Botón Siguiente */}
        <button
          className={`flex items-center justify-center px-3 py-2 rounded-md text-sm font-medium text-indigo-500 hover:text-indigo-600 focus:outline-none ${
            currentPage === totalPages && "opacity-50 cursor-not-allowed"
          }`}
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          <span className="text-lg">&rarr;</span>
          <span className="sr-only">Next</span>
        </button>
      </div>
    );
  }

  const deleteExpedient = async (id) => {
    var confirmacion = window.confirm(
      "¿Estás seguro de que quieres eliminar este expediente?"
    );
    if (confirmacion) {
      try {
        const response = await axios.delete(`${baseAPI}/expedients/${id}`);
        if (response.status === 200) {
          toast.success("Expediente eliminado! 😄 ", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setExpedients(expedients.filter((expedient) => expedient._id !== id));
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const viewExpedient = (id) => {
    window.location = `/view/${id}`;
  };

  return (
    <div className="col-span-full xl:col-span-12 bg-white shadow-lg rounded-sm border border-gray-200">
      <header className="px-5 py-4 border-b border-gray-100 flex justify-between items-center">
        <h2 className="font-semibold text-gray-800">Reclamaciones</h2>
        <button
          onClick={() => setShowFilter(!showFilter)}
          className="flex items-center text-sm font-medium text-gray-900 dark:text-gray-400"
        >
          {showFilter ? (
            <>
              Ocultar Filtros <span className="ml-2">🗂️</span>
            </>
          ) : (
            <>
              Mostrar Filtros <span className="ml-2">🗂️</span>
            </>
          )}
        </button>
      </header>

      <div className="p-3">
        {/* FILTRO */}
        {/* Selector de Estado */}

        {showFilter && (
          <div className="mb-5">
            <label
              htmlFor="estadoFilter"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
            >
              Filtrar por estado:
            </label>
            <select
              id="estadoFilter"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
              value={selectedEstado}
              onChange={(e) => setSelectedEstado(e.target.value)}
            >
              <option value="">Todos los Estados</option>
              <option value="On going">On going</option>
              <option value="Hot">Hot</option>
              <option value="Payment Intent">Payment Intent</option>
              <option value="Cold">Cold</option>
              <option value="Cancelled">Cancelled</option>
              <option value="Cold">Cold</option>
              <option value="Dispute">Dispute</option>
              <option value="Burofax Enviado">Burofax Enviado</option>
              <option value="Burofax con resultado negativo">
                Burofax con resultado negativo
              </option>
              <option value="Burofax con resultado positivo">
                Burofax con resultado positivo
              </option>
              <option value="Demanda pendiente">Demanda pendiente</option>
              <option value="Demanda preparada">Demanda preparada</option>
              <option value="Demanda presentada">Demanda presentada</option>
              <option value="Demanda Admitida">Demanda Admitida</option>
              <option value="Sentencia Dictada">Sentencia Dictada</option>
              <option value="Cobrado">Cobrado</option>
              <option value="Email opened">Email opened</option>
              <option value="Preparando reclamación">
                {" "}
                Preparando reclamación
              </option>
              <option value="bounced">Bounced</option>
            </select>
            {role === "admin" && (
              <>
                <label
                  htmlFor="clienteFilter"
                  className="block mb-2 mt-5 text-sm font-medium text-gray-900 dark:text-gray-400"
                >
                  Filtrar por cliente:
                </label>
                <select
                  id="clienteFilter"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                  value={selectedCliente}
                  onChange={(e) => setSelectedCliente(e.target.value)}
                >
                  <option value="">Todos los Clientes</option>
                  <option value="65a956d59cc123b8cc11b482">Cleverea</option>
                  <option value="6645ef7c7db4287499696cb1">Bmg Prime</option>
                  <option value="6645c3a47db42874996961f4">Max Energia</option>
                  <option value="665472566614bfb4bf6591e9">Mundimoto</option>
                  <option value="66b0d1dcd854b5c817148348">Feine Energia</option>
                </select>
              </>
            )}
          </div>
        )}

        {/* Aquí continúa el resto de tu código como la tabla... */}

        <div className="overflow-x-auto">
          <table className="table-auto w-full">
            <thead className="text-xs font-semibold uppercase text-gray-600 bg-gray-100">
              <tr>
                <th className="p-4 whitespace-nowrap">
                  <div className="font-semibold text-left">Fecha Creación</div>
                </th>
                <th className="p-4 whitespace-nowrap">
                  <div className="font-semibold text-left">Cliente</div>
                </th>
                <th className="p-4 whitespace-nowrap">
                  <div className="font-semibold text-left">Deudor</div>
                </th>
                <th
                  onClick={toggleSortOrder}
                  className="p-4 whitespace-nowrap cursor-pointer"
                >
                  <div className="font-semibold text-left flex items-center">
                    Importe Deuda{" "}
                    <span className="ml-2">
                      {sortOrder === "asc" ? "🔼" : "🔽"}
                    </span>
                  </div>
                </th>
                <th className="p-4 whitespace-nowrap">
                  <div className="font-semibold text-left">Estado</div>
                </th>
                <th className="p-4 whitespace-nowrap">
                  <div className="font-semibold text-left">Acciones</div>
                </th>
              </tr>
            </thead>
            <tbody className="text-sm divide-y divide-gray-100">
              {expedients.map((expedient) => (
                <Expedients
                  createdAt={expedient.createdAt}
                  expedient={expedient}
                  deleteExpedient={deleteExpedient}
                  viewExpedient={viewExpedient}
                  key={expedient._id}
                  role={role}
                />
              ))}
            </tbody>
          </table>
          {expedients.length === 0 && (
            <div className="text-center text-gray-400 py-8">
              No hay expedientes. !Crea uno nuevo!
            </div>
          )}
          {totalPages > 1 && (
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ExpedientsAdmin;
