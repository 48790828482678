import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import jwt_decode from "jwt-decode";
import CurrencyFormat from "react-currency-format";
import { baseAPI } from "../../config";
import DashboardCard09 from "../components/DashboardCard09";
import AnalyticsCard06 from "../components/AnalyticsCard06";

const DataDashboard = () => {
  const [totals, setTotals] = useState({
    totalReclamaciones: 0,
    totalDeuda: 0,
    totalRecuperado: 0,
    totalPendiente: 0,
    importesRecuperadosPorDia: [],
    estadosAgrupados: [],
  });
  const [filterUser, setFilterUser] = useState("");
  const [decoded, setDecoded] = useState({});
  const [clientsList] = useState([
    { id: "65a956d59cc123b8cc11b482", nombre: "Cleverea" },
    { id: "6645ef7c7db4287499696cb1", nombre: "Bmg Prime" },
    { id: "6645c3a47db42874996961f4", nombre: "Max Energía" },
    { id: "665472566614bfb4bf6591e9", nombre: "Mundimoto" },
    { id: "66b0d1dcd854b5c817148348", nombre: "Feine Energia" },
  ]);

  const fetchData = useCallback(async () => {
    const userToken = localStorage.getItem("token");
    if (!userToken) return;

    const decoded = jwt_decode(userToken);
    setDecoded(decoded);

    try {
      const { data } = await axios.get(`${baseAPI}/expedients/dashboard`, {
        params: { decoded, filterUser },
      });
      const calculatedTotals = calculateTotals(data);
      setTotals(calculatedTotals);
    } catch (error) {
      console.error(error);
    }
  }, [filterUser]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const chartData = {
    labels: totals.importesRecuperadosPorDia.map((item) => item.fecha),
    datasets: [
      {
        label: "Importes Recuperados",
        data: totals.importesRecuperadosPorDia.map((item) => item.importe),
        barPercentage: 0.66,
        categoryPercentage: 0.66,
      },
    ],
  };

  const calculateTotals = (expedientes) => {
    const sumaLasDeudas = Math.round(
      expedientes.reduce((acc, { importe_deuda }) => acc + importe_deuda, 0)
    );
    const filtraImportesRecuperados = expedientes.flatMap(
      ({ importes_recuperados }) => importes_recuperados
    );

    const sumaImportesRecuperados = Math.round(
      filtraImportesRecuperados.reduce((acc, { importe }) => acc + importe, 0)
    );

    let agruparImportesRecuperadosPorDia = [];
    let importesPorDia = new Map();

    filtraImportesRecuperados.forEach((item) => {
      let fechaSinHora = new Date(item.fecha);
      fechaSinHora.setHours(0, 0, 0, 0);
      let key = fechaSinHora.toISOString().split("T")[0]; // Clave como YYYY-MM-DD

      if (!importesPorDia.has(key)) {
        importesPorDia.set(key, { fecha: key, importe: 0 });
      }
      let acumulado = importesPorDia.get(key);
      acumulado.importe += item.importe;
    });

    agruparImportesRecuperadosPorDia = Array.from(importesPorDia.values());

    // Ordenar por fecha
    agruparImportesRecuperadosPorDia.sort(
      (a, b) => new Date(a.fecha) - new Date(b.fecha)
    );

    let estadosAgrupados = [];
    let estados = new Map();
    expedientes.forEach((item) => {
      let key = item.estado;
      if (!estados.has(key)) {
        estados.set(key, { estado: key, cantidad: 0, totalImporteDeuda: 0 });
      }
      let acumulado = estados.get(key);
      acumulado.cantidad += 1;
      acumulado.totalImporteDeuda += item.importe_deuda; // Suma el importe de deuda al acumulado
    });
    estadosAgrupados = Array.from(estados.values());

    // Ordenar por cantidad
    estadosAgrupados.sort((a, b) => b.cantidad - a.cantidad);

    // Retorna los totales y los importes recuperados por día
    return {
      totalReclamaciones: expedientes.length,
      totalDeuda: sumaLasDeudas,
      totalRecuperado: sumaImportesRecuperados,
      totalPendiente: sumaLasDeudas - sumaImportesRecuperados,
      importesRecuperadosPorDia: agruparImportesRecuperadosPorDia,
      estadosAgrupados: estadosAgrupados,
    };
  };

  const { totalReclamaciones, totalDeuda, totalRecuperado, totalPendiente } =
    totals;

  return (
    <div className="p-6 bg-gray-50 min-h-screen">
      <div className="flex justify-between items-center mb-8 bg-white shadow-md rounded-lg p-5">
        <div className="flex items-center">
          <h1 className="text-3xl font-extrabold text-gray-900">
            Bienvenido <span className="animate-wave">👋</span>, aquí podrás
            monitorizar el estado de tu deuda en tiempo real
          </h1>
        </div>
        <div>
          {decoded.role === "admin" && (
            <div className="flex flex-col sm:flex-row items-start sm:items-center">
              <label
                className="mb-2 sm:mb-0 sm:mr-3 font-medium text-gray-700 text-sm"
                htmlFor="clientSelector"
              >
                Filtrar por cliente:
              </label>
              <select
                id="clientSelector"
                value={filterUser}
                onChange={(e) => setFilterUser(e.target.value)}
                className="p-2 border border-gray-300 rounded-md text-sm bg-gray-50 hover:bg-gray-100 transition-colors w-full sm:w-64"
              >
                <option value="">Todos los clientes</option>
                {clientsList.map((client) => (
                  <option key={client.id} value={client.id}>
                    {client.nombre}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>
      </div>

      {/* Gráfico - Ocupa toda la parte superior */}
      <div className="mb-6">
        <DashboardCard09 chartData={chartData} />
      </div>

      {/* Funnel - Debajo del gráfico */}
      <div className="mb-6">
        <AnalyticsCard06 estadosAgrupados={totals.estadosAgrupados} />
      </div>

      {/* Tarjetas de Totales - Al final */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
        <div className="col-span-1">
          <DashboardCard
            title="Total Reclamaciones"
            value={totalReclamaciones}
            colorClass="bg-blue-500"
          />
        </div>
        <div className="col-span-1">
          <DashboardCard
            title="Total Deuda"
            value={totalDeuda}
            formatCurrency
            colorClass="bg-red-500"
          />
        </div>
        <div className="col-span-1">
          <DashboardCard
            title="Total Recuperado"
            value={totalRecuperado}
            formatCurrency
            colorClass="bg-green-500"
          />
        </div>
        <div className="col-span-1">
          <DashboardCard
            title="Deuda Pendiente"
            value={totalPendiente}
            formatCurrency
            colorClass="bg-yellow-500"
          />
        </div>
      </div>
    </div>
  );
};

const DashboardCard = ({
  title,
  value,
  formatCurrency,
  colorClass = "bg-gray-500",
}) => (
  <div className="flex flex-col col-span-full sm:col-span-6 xl:col-span-4 bg-white shadow-md rounded-lg border border-gray-200">
    <header className="px-5 py-4 border-b border-gray-100">
      <h2 className="font-semibold text-gray-800">{title}</h2>
    </header>
    <div className="px-5 py-3">
      <div className="flex items-center">
        <div
          className={`relative flex items-center justify-center w-4 h-4 rounded-full bg-gray-100 mr-3 ${colorClass}`}
          aria-hidden="true"
        ></div>
        <div className="text-3xl font-bold text-gray-800 mr-2">
          {formatCurrency ? (
            <CurrencyFormat
              value={value}
              displayType={"text"}
              thousandSeparator={"."}
              decimalSeparator={","}
              suffix={"€"}
            />
          ) : (
            value
          )}
        </div>
      </div>
    </div>
  </div>
);

export default DataDashboard;
