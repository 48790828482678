import React, { useState, useEffect } from "react";
import axios from "axios";
import { baseAPI } from "../../config";
import { toast } from "react-toastify";
import jwt_decode from "jwt-decode";

const AccountPanel = (props) => {
  const [userData, setUserData] = useState({
    username: "",
    userlastname: "",
    usermail: "",
    userphone: "",
    userrole: "",
    invoicedataname: "",
    invoicedataaddress: "",
    invoicedatacity: "",
    invoicedatacountry: "",
    invoicedatazip: "",
    invoicedatacif: "",
    invoicedataemail: "",
  });
  const [userRole, setUserRole] = useState("");

  useEffect(() => {
    const userId = props.userId;
    const token = localStorage.getItem("token");
    const decoded = jwt_decode(token);
    setUserRole(decoded.role);
    console.log("userRole:", userRole);
    axios
      .get(`${baseAPI}/users/` + userId)
      .then((response) => {
        setUserData(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [props.userId, userRole]);

  const onChange = (e) => {
    const targetName = e.target.name.split(".");
    console.log("e.target.name:", e.target.name);
    console.log("targetName:", targetName);
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const userId = props.userId;

    axios
      .post(`${baseAPI}/users/update/` + userId, userData)
      .then((res) => {
        toast.success("Usuario Actualizado! 😄 ", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        if (userRole !== "admin") {
        localStorage.setItem("token", res.data.token);
        }
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch(function (error) {
        console.log(error);
        alert("Error al actualizar el usuario");
      });
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
      <header className="px-5 py-4 border-b border-gray-100">
        <h2 className="font-semibold text-gray-800">Datos del usuario</h2>
      </header>
      <section className="px-5 py-4">
        <div className="space-y-8 mt-8">
          <form className="" method="POST" action="#" onSubmit={onSubmit}>
            <div className="grid gap-5 md:grid-cols-3">
              {/* Datos del usuario */}
              <div>
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="grid-username"
                >
                  Nombre
                </label>
                <input
                  className="rounded w-full text-gray-700 focus:outline-none border-b-1 border-gray-300 focus:border-blue-600 transition duration-500 px-3 pb-3"
                  id="grid-username"
                  type="text"
                  value={userData.username}
                  name="username"
                  onChange={onChange}
                />
              </div>
              <div>
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="grid-userlastname"
                >
                  Apellido
                </label>
                <input
                  className="rounded w-full text-gray-700 focus:outline-none border-b-1 border-gray-300 focus:border-blue-600 transition duration-500 px-3 pb-3"
                  id="grid-userlastname"
                  type="text"
                  value={userData.userlastname}
                  name="userlastname"
                  onChange={onChange}
                />
              </div>
              <div>
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="grid-usermail"
                >
                  Email
                </label>
                <input
                  className="rounded w-full text-gray-700 focus:outline-none border-b-1 border-gray-300 focus:border-blue-600 transition duration-500 px-3 pb-3"
                  id="grid-usermail"
                  type="text"
                  value={userData.usermail}
                  name="usermail"
                  onChange={onChange}
                />
              </div>
              <div>
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="grid-userphone"
                >
                  Teléfono
                </label>
                <input
                  className="rounded w-full text-gray-700 focus:outline-none border-b-1 border-gray-300 focus:border-blue-600 transition duration-500 px-3 pb-3"
                  id="grid-userphone"
                  type="text"
                  value={userData.userphone}
                  name="userphone"
                  onChange={onChange}
                />
              </div>

              {/* Datos de facturación */}
              <div>
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="grid-invoicename"
                >
                  Nombre de facturación
                </label>
                <input
                  className="rounded w-full text-gray-700 focus:outline-none border-b-1 border-gray-300 focus:border-blue-600 transition duration-500 px-3 pb-3"
                  id="grid-invoicename"
                  type="text"
                  value={userData.invoicedataname}
                  name="invoicedataname"
                  onChange={onChange}
                />
              </div>
              <div>
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="grid-invoiceaddress"
                >
                  Dirección
                </label>
                <input
                  className="rounded w-full text-gray-700 focus:outline-none border-b-1 border-gray-300 focus:border-blue-600 transition duration-500 px-3 pb-3"
                  id="grid-invoiceaddress"
                  type="text"
                  value={userData.invoicedataaddress}
                  name="invoicedataaddress"
                  onChange={onChange}
                />
              </div>
              <div>
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="grid-invoicecity"
                >
                  Ciudad
                </label>
                <input
                  className="rounded w-full text-gray-700 focus:outline-none border-b-1 border-gray-300 focus:border-blue-600 transition duration-500 px-3 pb-3"
                  id="grid-invoicecity"
                  type="text"
                  value={userData.invoicedatacity}
                  name="invoicedatacity"
                  onChange={onChange}
                />
              </div>
              <div>
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="grid-invoicecode"
                >
                  Código Postal
                </label>
                <input
                  className="rounded w-full text-gray-700 focus:outline-none border-b-1 border-gray-300 focus:border-blue-600 transition duration-500 px-3 pb-3"
                  id="grid-invoicecode"
                  type="text"
                  value={userData.invoicedatazip}
                  name="invoicedatazip"
                  onChange={onChange}
                />
              </div>
              <div>
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="grid-invoicescountry"
                >
                  País
                </label>
                <input
                  className="rounded w-full text-gray-700 focus:outline-none border-b-1 border-gray-300 focus:border-blue-600 transition duration-500 px-2 pb-3"
                  id="grid-invoicescountry"
                  type="text"
                  value={userData.invoicedatacountry}
                  name="invoicedatacountry"
                  onChange={onChange}
                />
              </div>
              <div>
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="grid-invoicencif"
                >
                  CIF/NIF
                </label>
                <input
                  className="rounded w-full text-gray-700 focus:outline-none border-b-1 border-gray-300 focus:border-blue-600 px-3 pb-3"
                  id="grid-invoicencif"
                  type="text"
                  value={userData.invoicedatacif}
                  name="invoicedatacif"
                  onChange={onChange}
                />
              </div>
              <div>
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="grid-invoiceemail"
                >
                  Email de facturación
                </label>
                <input
                  className="rounded w-full text-gray-700 focus:outline-none border-b-1 border-gray-300 focus:border-blue-600 px-3 pb-3"
                  id="grid-invoiceemail"
                  type="text"
                  value={userData.invoicedataemail}
                  name="invoicedataemail"
                  onChange={onChange}
                />
              </div>

              {/* Aquí podrías repetir este patrón para los demás campos de facturación: dirección, ciudad, estado, código postal, país, cif e invoiceemail */}
            </div>
            <button
              className="btn mt-5 bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 rounded shadow-lg hover:shadow-xl transition duration-200"
              type="submit"
            >
              Guardar
            </button>
          </form>
        </div>
      </section>
    </div>
  );
};

export default AccountPanel;
