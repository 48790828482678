import React, { useState, useEffect } from "react";
import axios from "axios";
import { baseAPI } from "../../config";
import { toast } from "react-toastify";

function UpdateExpedient(props) {
  const [importe_deuda, setImporteDeuda] = useState(0);
  const [tipo_deuda, setTipoDeuda] = useState("");
  const [presupuesto, setPresupuesto] = useState(0);
  const [porcentajeBudget, setPorcentajeBudget] = useState(0);
  const [porcentajeDescuento, setPorcentajeDescuento] = useState(0);
  const [estado, setEstado] = useState("");
  const [expedientId] = useState(props.idExpedient);
  const [aditionalExpedientData, setAditionalExpedientData] = useState("");
  const [clientInternalReference, setClientInternalReference] = useState("");

  useEffect(() => {
    axios
      .get(`${baseAPI}/expedients/` + expedientId)
      .then((response) => {
        setImporteDeuda(response.data.importe_deuda);
        setTipoDeuda(response.data.tipo_deuda);
        setEstado(response.data.estado);
        setPresupuesto(response.data.presupuesto);
        setPorcentajeBudget(response.data.porcentaje_presupuesto);
        setPorcentajeDescuento(response.data.porcentaje_descuento_deuda);
        setClientInternalReference(response.data.client_internal_reference);
        setAditionalExpedientData(response.data.aditional_expedient_data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [expedientId]);

  const onChangeImporteDeuda = (e) => {
    setImporteDeuda(e.target.value.replace(",", "."));
  };

  const onChangeTipoDeuda = (e) => {
    setTipoDeuda(e.target.value);
  };

  const onChangeEstado = (e) => {
    setEstado(e.target.value);
  };

  const onChangePresupuesto = (e) => {
    setPresupuesto(e.target.value.replace(",", "."));
  };

  const onChangePorcentajeBudget = (e) => {
    setPorcentajeBudget(e.target.value.replace(",", "."));
  };

  const onChangePorcentajeDescuento = (e) => {
    setPorcentajeDescuento(e.target.value.replace(",", "."));
  };

  const onChangeClientInternalReference = (e) => {
    setClientInternalReference(e.target.value);
  };

  const onChangeAditionalExpedientData = (e) => {
    setAditionalExpedientData(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const expedient = {
      importe_deuda: importe_deuda,
      tipo_deuda: tipo_deuda,
      presupuesto: presupuesto,
      porcentaje_presupuesto: porcentajeBudget,
      estado: estado,
      porcentaje_descuento_deuda: porcentajeDescuento,
      client_internal_reference: clientInternalReference,
      aditional_expedient_data: aditionalExpedientData,
    };
    console.log(expedient);
    console.log(baseAPI);
    async function updateExpedientId(expedient) {
      const response = await axios.post(
        `${baseAPI}/expedients/update/${expedientId}`,
        expedient
      );
      return response;
    }
    updateExpedientId(expedient).then((response) => {
      if (response.status === 200) {
        toast.success("Expediente actualizado! 😄 ", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    });
  };

  return (
    <div className="ml-4  bg-white shadow-lg rounded-sm border border-gray-200">
      <header className="px-5 py-4 border-b border-gray-100">
        <h2 className="font-semibold text-gray-800">Actualizar Expediente</h2>
      </header>
      <section className="px-5 py-4 ">
        <div className="container mt-5 ">
          <form
            className="flex flex-col flex-wrap "
            method="POST"
            action="#"
            onSubmit={onSubmit}
          >
            <h2 className="block pb-3 text-blue-500 whitespace-nowrap border-b-2 border-blue-500">
              Datos del Expediente
            </h2>

            <div className="flex flex-wrap -mx-3 mb-6 mt-5">
              <div className="w-full md:w-1/2 px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1 mt-2"
                  htmlFor="grid-last-name"
                >
                  Importe de la Deuda
                </label>
                <input
                  className=" rounded w-full text-gray-700 focus:outline-none border-b-1 border-gray-300 focus:border-blue-600 transition duration-500 px-3 pb-3"
                  id="grid-last-name"
                  type="number"
                  placeholder="Deuda"
                  onChange={onChangeImporteDeuda}
                  required={true}
                  value={importe_deuda}
                />
              </div>
              <div className="w-full md:w-1/2 px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1 mt-2"
                  htmlFor="grid-last-name"
                >
                  Tipo de deuda
                </label>
                <select
                  className="rounded w-full text-gray-700 focus:outline-none border-b-1 border-gray-300 focus:border-blue-600 transition duration-500 px-3 pb-3"
                  onChange={onChangeTipoDeuda}
                  required={true}
                  value={tipo_deuda}
                >
                  <option>-Selecciona una opción-</option>
                  <option>Factura impagada</option>
                  <option>Reconocimiento de deuda</option>
                  <option>Préstamo personal sin garantía</option>
                  <option>Préstamo hipotecario</option>
                  <option>Pagaré</option>
                  <option>Tarjeta de crédito</option>
                  <option>Tarjeta revolving</option>
                  <option>Impago suscripción recurrente</option>
                  <option>Contrato de arrendamiento</option>
                  <option>Otro tipo de deuda</option>
                </select>
              </div>
              <div className="w-full md:w-1/2 px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1 mt-2"
                  htmlFor="grid-last-name"
                >
                  Presupuesto fijo
                </label>
                <input
                  className=" rounded w-full text-gray-700 focus:outline-none border-b-1 border-gray-300 focus:border-blue-600 transition duration-500 px-3 pb-3"
                  id="grid-last-name"
                  type="number"
                  onChange={onChangePresupuesto}
                  value={presupuesto}
                />
              </div>
              <div className="w-full md:w-1/2 px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1 mt-2"
                  htmlFor="grid-last-name"
                >
                  % de éxito
                </label>
                <input
                  className=" rounded w-full text-gray-700 focus:outline-none border-b-1 border-gray-300 focus:border-blue-600 transition duration-500 px-3 pb-3"
                  id="grid-last-name"
                  type="number"
                  placeholder="% de éxito"
                  onChange={onChangePorcentajeBudget}
                  value={porcentajeBudget}
                />
              </div>
              <div className="w-full md:w-1/2 px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1 mt-2"
                  htmlFor="grid-last-name"
                >
                  Referencia cliente
                </label>
                <input
                  className=" rounded w-full text-gray-700 focus:outline-none border-b-1 border-gray-300 focus:border-blue-600 transition duration-500 px-3 pb-3"
                  id="grid-last-name"
                  type="text"
                  placeholder="Referencia cliente"
                  onChange={onChangeClientInternalReference}
                  value={clientInternalReference}
                />
              </div>
              <div className="w-full md:w-1/2 px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1 mt-2"
                  htmlFor="grid-last-name"
                >
                  Información adicional
                </label>
                <input
                  className=" rounded w-full text-gray-700 focus:outline-none border-b-1 border-gray-300 focus:border-blue-600 transition duration-500 px-3 pb-3"
                  id="grid-last-name"
                  type="text"
                  placeholder="Información adicional"
                  onChange={onChangeAditionalExpedientData}
                  value={aditionalExpedientData}
                />
              </div>
              <div className="w-full md:w-1/2 px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1 mt-2"
                  htmlFor="grid-last-name"
                >
                  Estado
                </label>
                <select
                  className="rounded w-full text-gray-700 focus:outline-none border-b-1 border-gray-300 focus:border-blue-600 transition duration-500 px-3 pb-3"
                  onChange={onChangeEstado}
                  required={true}
                  value={estado}
                >
                  <option>Preparando reclamación</option>
                  <option>On going</option>
                  <option>Hot</option>
                  <option>Cold</option>
                  <option>Cancelled</option>
                  <option>Cold</option>
                  <option>Dispute</option>
                  <option>Burofax Enviado</option>
                  <option>Burofax con resultado negativo</option>
                  <option>Burofax con resultado positivo</option>
                  <option>Demanda pendiente</option>
                  <option>Demanda preparada</option>
                  <option>Demanda presentada</option>
                  <option>Demanda Admitida</option>
                  <option>Sentencia Dictada</option>
                  <option>Cobrado</option>
                  <option>Email opened</option>
                  <option>Bounced</option>
                </select>
              </div>
              <div className="w-full md:w-1/2 px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1 mt-2"
                  htmlFor="grid-last-name"
                >
                  % de descuento deuda
                </label>
                <input
                  className=" rounded w-full text-gray-700 focus:outline-none border-b-1 border-gray-300 focus:border-blue-600 transition duration-500 px-3 pb-3"
                  id="grid-last-name"
                  type="number"
                  placeholder="% de descuento"
                  onChange={onChangePorcentajeDescuento}
                  value={porcentajeDescuento}
                />
              </div>
            </div>
            <button
              className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 rounded shadow-lg hover:shadow-xl transition duration-200"
              type="submit"
            >
              Actualizar Expediente
            </button>
          </form>
        </div>
      </section>
    </div>
  );
}

export default UpdateExpedient;
