import { Popover } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { useState } from "react";
import axios from "axios";
import { baseAPI } from "../../config";
import jwt_decode from "jwt-decode";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Opciones de acciones
const actions = [{ name: "Enviar Email", action: "sendEmail" }];

// Modal para el formulario de enviar email
function EmailModal({
  isOpen,
  onClose,
  onSend,
  subject,
  setSubject,
  message,
  setMessage,
}) {
  return (
    <>
      {isOpen && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen px-4">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
            <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full">
              <div className="bg-white p-6">
                <h3 className="text-lg font-semibold text-gray-800 mb-4">
                  Enviar Email Personalizado
                </h3>
                <form onSubmit={onSend}>
                  <div className="mb-4">
                    <label
                      htmlFor="subject"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Asunto:
                    </label>
                    <input
                      type="text"
                      id="subject"
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                      value={subject}
                      onChange={(e) => setSubject(e.target.value)}
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="message"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Mensaje:
                    </label>
                    <textarea
                      id="message"
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                      rows="4"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      required
                    ></textarea>
                  </div>
                  <div className="flex justify-end space-x-2">
                    <button
                      type="button"
                      className="text-gray-500 hover:text-gray-700"
                      onClick={onClose}
                    >
                      Cancelar
                    </button>
                    <button
                      type="submit"
                      className="bg-indigo-600 hover:bg-indigo-700 text-white font-semibold py-2 px-4 rounded-md"
                    >
                      Enviar Email
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

// Componente principal
export default function ActionMenu({ expedientId }) {
  const userToken = localStorage.getItem("token");
  const decodedUser = jwt_decode(userToken);

  const [emailSubject, setEmailSubject] = useState("");
  const [emailMessage, setEmailMessage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false); // Controla si el modal está visible

  // Función para manejar el envío del email
  const handleSendEmail = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${baseAPI}/actions/sendemailwithouttemplate`, {
        expedientId, // ID del expediente pasado como prop
        userSesion: decodedUser, // Información de la sesión del usuario decodificado
        textEmail: emailMessage, // Cuerpo del mensaje
        subjectEmail: emailSubject, // Asunto del correo
      });
      toast.success("Correo enviado exitosamente!"); // Notificación de éxito
      setEmailMessage(""); // Limpiar el campo de mensaje después del envío
      setEmailSubject("");
      setIsModalOpen(false); // Cerrar el modal después de enviar
    } catch (error) {
      console.error("Error al enviar el email:", error);
      toast.error("Error al enviar el correo"); // Notificación de error
    }
  };

  return (
    <div>
      <ToastContainer /> {/* Contenedor de toasts para las notificaciones */}
      <Popover className="relative">
        <Popover.Button className="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
          <EllipsisVerticalIcon aria-hidden="true" className="h-5 w-5" />
        </Popover.Button>

        <Popover.Panel
          transition
          className="absolute left-1/2 z-10 mt-5 flex w-screen max-w-min -translate-x-1/2 px-4 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
        >
          <div className="w-56 shrink rounded-xl bg-white p-4 text-sm font-semibold leading-6 text-gray-900 shadow-lg ring-1 ring-gray-900/5">
            {actions.map((item) => (
              <button
                key={item.name}
                onClick={() => setIsModalOpen(true)} // Mostrar el modal cuando se hace clic en "Enviar Email"
                className="block w-full text-left p-2 hover:text-indigo-600"
              >
                {item.name}
              </button>
            ))}
          </div>
        </Popover.Panel>
      </Popover>
      {/* Modal para el formulario de envío de email */}
      <EmailModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSend={handleSendEmail}
        subject={emailSubject}
        setSubject={setEmailSubject}
        message={emailMessage}
        setMessage={setEmailMessage}
      />
    </div>
  );
}
