import React, { useState, useEffect } from "react";
import axios from "axios";
import { baseAPI } from "../config";
import CurrencyFormat from "react-currency-format";
import moment from "moment";
import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import Footer from "../partials/Footer";
import WelcomeBanner from "../partials/components/WelcomeBanner";
import ActionMenu from "../partials/actions/ActionsMenu";

import { EyeIcon, EyeSlashIcon, PencilIcon } from "@heroicons/react/20/solid";

// Función para aplicar estilos condicionales a Total Interacciones
const getInteractionStyle = (totalInteractions) => {
  if (totalInteractions > 15) {
    return "bg-red-100 text-red-600"; // Fondo rojo claro y texto rojo
  } else if (totalInteractions >= 5) {
    return "bg-green-100 text-green-600"; // Fondo verde claro y texto verde
  } else {
    return "bg-orange-100 text-orange-600"; // Fondo naranja claro y texto naranja
  }
};

const Interactions = ({ interaction }) => {
  const [showAnnotations, setShowAnnotations] = useState(false); // Estado para controlar la visibilidad de las anotaciones

  const formattedDate = interaction.createdAt
    ? moment(interaction.createdAt).format("DD/MM/YYYY")
    : "No Date";
  const importeDeudaFormatted = interaction.importe_deuda / 1;

  return (
    <>
      <tr>
        <td className="px-3 py-2 whitespace-nowrap">
          <div className="text-left text-gray-800">{formattedDate}</div>
        </td>
        <td className="px-3 py-2 whitespace-nowrap">
          <div className="text-left text-gray-800">{interaction.user_id}</div>
        </td>
        <td className="px-3 py-2 whitespace-nowrap">
          <div className="text-left text-gray-800">
            <CurrencyFormat
              value={importeDeudaFormatted}
              displayType={"text"}
              thousandSeparator={"."}
              decimalSeparator={","}
              suffix={" €"}
              decimalScale={2}
            />
          </div>
        </td>

        {/* Aquí ajustamos el ancho de la columna de interacciones */}
        <td className="px-3 py-2 w-[70px] whitespace-nowrap text-center">
          <div
            className={`text-center font-semibold px-2 py-1 rounded ${getInteractionStyle(
              interaction.total_interactions
            )}`}
          >
            {interaction.total_interactions}
            {interaction.total_interactions > 15 && " 🔥"}
          </div>
        </td>

        <td className="px-3 py-2 whitespace-nowrap">
          <div className="flex space-x-2 items-center">
            {/* Botón para ver el expediente */}
            <button
              className="text-indigo-500 hover:text-indigo-600"
              onClick={() =>
                (window.location.href = `/view/${interaction.expedient_id}`)
              }
            >
              <PencilIcon
                className="h-4 w-4 sm:h-5 sm:w-5"
                aria-hidden="true"
              />
            </button>

            {/* Botón para mostrar/ocultar anotaciones */}
            <button
              className="text-indigo-500 hover:text-indigo-600"
              onClick={() => setShowAnnotations(!showAnnotations)}
            >
              {showAnnotations ? (
                <EyeSlashIcon
                  className="h-4 w-4 sm:h-5 sm:w-5"
                  aria-hidden="true"
                />
              ) : (
                <EyeIcon className="h-4 w-4 sm:h-5 sm:w-5" aria-hidden="true" />
              )}
            </button>

            {/* ActionMenu para enviar email */}
            <ActionMenu expedientId={interaction.expedient_id} />
          </div>
        </td>
      </tr>

      {/* Renderizar las anotaciones si showAnnotations está activo */}
      {showAnnotations && (
        <tr>
          <td colSpan="5" className="bg-gray-50">
            <div className="p-4 border-t border-gray-200 rounded-b-lg">
              <h3 className="font-semibold text-gray-800 mb-4">
                📌 Anotaciones
              </h3>
              {interaction.annotations.length > 0 ? (
                <div className="space-y-3">
                  {interaction.annotations.map((annotation) => (
                    <div
                      key={annotation._id}
                      className="bg-white p-3 shadow-sm rounded-lg border border-gray-200"
                    >
                      <div className="text-sm">
                        <div className="font-semibold text-gray-700 mb-1">
                          ✏️ <strong>Anotación:</strong> {annotation.anotacion}
                        </div>
                        <div className="text-gray-500">
                          📅 <strong>Fecha:</strong>{" "}
                          {moment(annotation.fecha).format("DD/MM/YYYY")}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <p className="text-gray-500">No hay anotaciones.</p>
              )}
            </div>
          </td>
        </tr>
      )}
    </>
  );
};

const InteractionsList = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [interactions, setInteractions] = useState([]); // Estado para guardar las interacciones
  const [loading, setLoading] = useState(true); // Estado para controlar la carga
  const [sortField, setSortField] = useState("createdAt"); // Campo para ordenar
  const [sortOrder, setSortOrder] = useState("asc"); // Orden asc o desc

  const userToken = localStorage.getItem("token");

  if (userToken == null) {
    window.location = "/login";
  }

  // Llamada a la API para obtener las interacciones
  useEffect(() => {
    const fetchInteractions = async () => {
      try {
        const response = await axios.get(
          `${baseAPI}/interactions/opened_emails/last7days`
        );
        setInteractions(response.data); // Guardar los datos de la API en el estado
      } catch (error) {
        console.error("Error fetching interactions", error);
      } finally {
        setLoading(false); // Dejar de mostrar el loader
      }
    };

    fetchInteractions();
  }, [userToken]);

  // Función para ordenar las interacciones
  const sortInteractions = (field) => {
    const order = sortField === field && sortOrder === "asc" ? "desc" : "asc"; // Cambia el orden si ya está ordenado por el mismo campo
    setSortField(field);
    setSortOrder(order);

    const sortedInteractions = [...interactions].sort((a, b) => {
      let comparison = 0;
      if (field === "createdAt") {
        comparison = new Date(a.createdAt) - new Date(b.createdAt);
      } else if (field === "importe_deuda") {
        comparison = a.importe_deuda - b.importe_deuda;
      } else if (field === "total_interactions") {
        comparison = a.total_interactions - b.total_interactions;
      }
      return order === "asc" ? comparison : -comparison;
    });

    setInteractions(sortedInteractions);
  };

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/* Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-6 w-full max-w-9xl mx-auto">
            {/* Welcome banner */}
            <WelcomeBanner />

            {/* Título de la tabla */}
            <header className="px-4 py-3 border-b border-gray-100 flex justify-between items-center">
              <h2 className="font-semibold text-gray-800">
                Interacciones de los últimos 7 días
              </h2>
            </header>

            {/* Tabla de interacciones */}
            <div className="grid grid-cols-12 gap-4 mt-4">
              {loading ? (
                <p className="text-center col-span-12">Cargando...</p>
              ) : (
                <div className="col-span-12">
                  <div className="overflow-x-auto">
                    <table className="table-auto w-full text-left">
                      <thead className="text-xs font-semibold uppercase text-gray-600 bg-gray-100">
                        <tr>
                          <th
                            className="px-3 py-2 cursor-pointer"
                            onClick={() => sortInteractions("createdAt")}
                          >
                            <div className="flex items-center">
                              Fecha Creación{" "}
                              {sortField === "createdAt" &&
                                (sortOrder === "asc" ? "🔼" : "🔽")}
                            </div>
                          </th>
                          <th className="px-3 py-2">User ID</th>
                          <th
                            className="px-3 py-2 cursor-pointer"
                            onClick={() => sortInteractions("importe_deuda")}
                          >
                            <div className="flex items-center">
                              Importe Deuda{" "}
                              {sortField === "importe_deuda" &&
                                (sortOrder === "asc" ? "🔼" : "🔽")}
                            </div>
                          </th>
                          <th
                            className="px-3 py-2 cursor-pointer"
                            onClick={() =>
                              sortInteractions("total_interactions")
                            }
                          >
                            <div className="flex items-center">
                              Interacciones{" "}
                              {sortField === "total_interactions" &&
                                (sortOrder === "asc" ? "🔼" : "🔽")}
                            </div>
                          </th>
                          <th className="px-3 py-2">Acciones</th>
                        </tr>
                      </thead>
                      <tbody className="text-sm divide-y divide-gray-200">
                        {interactions.map((interaction) => (
                          <Interactions
                            key={interaction.expedient_id}
                            interaction={interaction}
                          />
                        ))}
                      </tbody>
                    </table>
                    {interactions.length === 0 && (
                      <div className="text-center text-gray-400 py-6">
                        No hay interacciones registradas.
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default InteractionsList;
