import React, { Component } from "react";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { baseAPI } from "../../config";
import UpdateDebtor from "./UpdateDebtorModal";
import { toast } from "react-toastify";
import CreateBurofax from "../../services/createBurofax";

const PrintDeudores = (props) => (
  <div className="col-span-full sm:col-span-6 xl:col-span-8 bg-white shadow-lg rounded-sm border border-slate-200 w-full">
    <div className="flex flex-col h-full w-full">
      <div className="grow p-5 w-full">
        <div className="flex justify-between items-start w-full">
          <header>
            <div className="flex mb-2 w-full">
              <div className="mt-1 pr-1 w-full">
                <h2 className="text-xl leading-snug justify-center font-semibold">
                  {props.deudor.debtorname}
                </h2>
                <div className="flex items-center w-full">
                  <span className="text-sm font-medium text-slate-400 -mt-0.5 mr-1">
                    -&gt;
                  </span>{" "}
                  <span>{props.deudor.debtorphone}</span>
                </div>
              </div>
            </div>
          </header>
          {/* Menu button */}
        </div>
        {/* Bio */}
        <div className="mt-2 w-full">
          <div className="text-sm w-full">
            {props.deudor.debtoradress}, {props.deudor.debtorcity},{" "}
            {props.deudor.debtorzip}
          </div>
        </div>
      </div>
      {props.decodedRole === "admin" && (
        <div className="border-t border-slate-200 w-full">
          <div className="flex flex-wrap divide-x divide-slate-200r w-full">
            {props.burofax && (
              <CreateBurofax
                idExpedient={props.idExpedient}
                debtor={props.deudor}
                expedient={props.expedient}
              />
            )}
            {props.email && (
              <button
                className="block flex-1 text-center text-sm text-blue-500 hover:text-blue-600 font-medium px-3 py-4"
                onClick={() => SendEmailToDebtor(props.deudor._id)}
              >
                <div className="flex items-center justify-center w-full">
                  <svg
                    className="w-4 h-4 fill-current shrink-0 mr-2"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 14H4V8l8 5 8-5v10zm-8-7L4 6h16l-8 5z"></path>
                  </svg>
                  <span className="w-full">
                    Mail {props.emailUtilizados}/{props.emailTotal}
                  </span>
                </div>
              </button>
            )}
            {props.sms && (
              <button
                className="block flex-1 text-center text-sm text-blue-500 hover:text-blue-600 font-medium px-3 py-4"
                onClick={() => SendSmsToDebtor(props.deudor._id)}
              >
                <div className="flex items-center justify-center w-full">
                  <svg
                    className="w-4 h-4 fill-current shrink-0 mr-2"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 0C3.6 0 0 3.1 0 7s3.6 7 8 7h.6l5.4 2v-4.4c1.2-1.2 2-2.8 2-4.6 0-3.9-3.6-7-8-7zm4 10.8v2.3L8.9 12H8c-3.3 0-6-2.2-6-5s2.7-5 6-5 6 2.2 6 5c0 2.2-2 3.8-2 3.8z" />
                  </svg>
                  <span className="w-full">
                    Sms {props.smsUtilizados}/{props.smsTotal}
                  </span>
                </div>
              </button>
            )}
            {props.llamada_robotizada && (
              <button
                className="block flex-1 text-center text-sm text-blue-500 hover:text-blue-600 font-medium px-3 py-4"
                onClick={() => CallDebtor(props.deudor._id)}
              >
                <div className="flex items-center justify-center w-full">
                  <svg
                    className="w-4 h-4 fill-current shrink-0 mr-2"
                    viewBox="0 0 24 24"
                  >
                    <path d="M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1V20c0 .55-.45 1-1 1-9.39 0-17-7.61-17-17 0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2z"></path>
                  </svg>
                  <span className="w-full">
                    Llamada {props.llamadaRobotizadaUtilizados}/
                    {props.llamadaRobotizadaTotal}
                  </span>
                </div>
              </button>
            )}
            <UpdateDebtor idDebtor={props.deudor._id} />
          </div>
        </div>
      )}
    </div>
  </div>
);

function SendEmailToDebtor(debtoriId) {
  const userToken = localStorage.getItem("token");
  const decoded = jwt_decode(userToken);
  let certificatedEmail = false;
  if (window.confirm("¿Quieres enviar un email certificado?")) {
    certificatedEmail = true;
  }

  const dataToSendBackend = {
    debtorId: debtoriId,
    expedientId: window.location.pathname.split("/")[2],
    certificatedEmail: certificatedEmail,
    userSesion: decoded,
  };
  if (window.confirm("¿Seguro que quieres enviar el mail?")) {
    axios
      .post(`${baseAPI}/actions/sendEmailToDebtor`, dataToSendBackend)
      .then((res) => {
        console.log(res);
        toast.success("Email enviado correctamente");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error al enviar el email");
      });
  }
}

function SendSmsToDebtor(debtoriId) {
  const userToken = localStorage.getItem("token");
  const decoded = jwt_decode(userToken);

  const dataToSendBackend = {
    debtorId: debtoriId,
    expedientId: window.location.pathname.split("/")[2],
    userSesion: decoded,
  };
  if (window.confirm("¿Seguro que quieres enviar un SMS al deudor?")) {
    axios
      .post(`${baseAPI}/actions/sendsmstodebtor`, dataToSendBackend)
      .then((res) => {
        console.log(res);
        toast.success("SMS enviado correctamente");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error al enviar el email");
      });
  }
}

function CallDebtor(debtoriId) {
  const userToken = localStorage.getItem("token");
  const decoded = jwt_decode(userToken);

  const dataToSendBackend = {
    debtorId: debtoriId,
    expedientId: window.location.pathname.split("/")[2],
    userSesion: decoded,
  };

  if (window.confirm("¿Seguro que quieres enviar una llamada al deudor?")) {
    axios
      .post(`${baseAPI}/actions/calltodebtor`, dataToSendBackend)
      .then((res) => {
        console.log(res);
        toast.success("Llamada realizada correctamente");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error al realizar la llamada");
      });
  }
}

function getServiceByType(expediente, type) {
  const servicio = expediente.servicios.find((s) => s.tipo === type);
  return servicio || {};
}

export default class Debtors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expedientid: props.idExpedient,
      expediente: {},
      deudores: [],
      user_id: "",
      crear_deudores: false,
      nombre_nuevo_deudor: "",
      domicilio_nuevo_deudor: "",
      ciudad_nuevo_deudor: "",
      cp_nuevo_deudor: "",
      telefono_nuevo_deudor: "",
      mail_nuevo_deudor: "",
      user_role: "",
      email: false,
      sms: false,
      llamada_personal: false,
      llamada_robotizada: false,
      burofax: false,
      smsUtilizados: 0,
      smsTotal: 0,
      emailUtilizados: 0,
      emailTotal: 0,
      llamadaRobotizadaUtilizados: 0,
      llamadaRobotizadaTotal: 0,
    };

    this.handleClick = this.handleClick.bind(this);
    this.onChangeNombreNuevoDeudor = this.onChangeNombreNuevoDeudor.bind(this);
    this.onChangeDomicilioNuevoDeudor =
      this.onChangeDomicilioNuevoDeudor.bind(this);
    this.onChangeCiudadNuevoDeudor = this.onChangeCiudadNuevoDeudor.bind(this);
    this.onChangeCPNuevoDeudor = this.onChangeCPNuevoDeudor.bind(this);
    this.onChangeTelefonoNuevoDeudor =
      this.onChangeTelefonoNuevoDeudor.bind(this);
    this.onChangeMailNuevoDeudor = this.onChangeMailNuevoDeudor.bind(this);
    this.onSubmitCreateDebtors = this.onSubmitCreateDebtors.bind(this);
  }

  componentDidMount() {
    const idExp = this.props.idExpedient;
    const userToken = localStorage.getItem("token");
    const decoded = jwt_decode(userToken);
    const userid = decoded.id;
    this.setState({
      user_role: decoded.role,
    });
    axios
      .get(`${baseAPI}/expedients/` + idExp)
      .then((response) => {
        const expediente = response.data;
        const emailService = getServiceByType(expediente, "email");
        const smsService = getServiceByType(expediente, "sms");
        const automaticCallService = getServiceByType(
          expediente,
          "llamada_robotizada"
        );
        expediente.servicios.forEach((servicio) => {
          if (servicio.tipo === "email") {
            this.setState({
              email: true,
            });
          }
          if (servicio.tipo === "sms") {
            this.setState({
              sms: true,
            });
          }
          if (servicio.tipo === "llamada_personal") {
            this.setState({
              llamada_personal: true,
            });
          }
          if (servicio.tipo === "llamada_robotizada") {
            this.setState({
              llamada_robotizada: true,
            });
          }
          if (servicio.tipo === "burofax") {
            this.setState({
              burofax: true,
            });
          }
        });
        this.setState({
          expediente: response.data,
          emailUtilizados: emailService.utilizados || 0,
          emailTotal: emailService.cantidad || 0,
          smsUtilizados: smsService.utilizados || 0,
          smsTotal: smsService.cantidad || 0,
          llamadaRobotizadaUtilizados: automaticCallService.utilizados || 0,
          llamadaRobotizadaTotal: automaticCallService.cantidad || 0,
          user_id: userid,
          deudores: response.data.deudores_id,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  handleClick(e) {
    e.preventDefault();
    this.setState({
      crear_deudores: true,
    });
  }

  onChangeNombreNuevoDeudor(e) {
    e.preventDefault();
    this.setState({
      nombre_nuevo_deudor: e.target.value,
    });
  }

  onChangeDomicilioNuevoDeudor(e) {
    e.preventDefault();
    this.setState({
      domicilio_nuevo_deudor: e.target.value,
    });
  }

  onChangeCiudadNuevoDeudor(e) {
    e.preventDefault();
    this.setState({
      ciudad_nuevo_deudor: e.target.value,
    });
  }

  onChangeCPNuevoDeudor(e) {
    e.preventDefault();
    this.setState({
      cp_nuevo_deudor: e.target.value,
    });
  }

  onChangeTelefonoNuevoDeudor(e) {
    e.preventDefault();
    this.setState({
      telefono_nuevo_deudor: e.target.value,
    });
  }

  onChangeMailNuevoDeudor(e) {
    e.preventDefault();
    this.setState({
      mail_nuevo_deudor: e.target.value,
    });
  }

  debtorsList() {
    return this.state.deudores.map((currentDeudor) => {
      return (
        <PrintDeudores
          deudor={currentDeudor}
          key={currentDeudor._id}
          SendEmailDebtor={SendEmailToDebtor}
          expedient={this.state.expediente}
          decodedRole={this.state.user_role}
          sms={this.state.sms}
          email={this.state.email}
          llamada_personal={this.state.llamada_personal}
          llamada_robotizada={this.state.llamada_robotizada}
          burofax={this.state.burofax}
          smsUtilizados={this.state.smsUtilizados}
          smsTotal={this.state.smsTotal}
          emailUtilizados={this.state.emailUtilizados}
          emailTotal={this.state.emailTotal}
          llamadaRobotizadaUtilizados={this.state.llamadaRobotizadaUtilizados}
          llamadaRobotizadaTotal={this.state.llamadaRobotizadaTotal}
        />
      );
    });
  }

  onSubmitCreateDebtors(e) {
    e.preventDefault();
    const newDebtor = {
      nombre: this.state.nombre_nuevo_deudor,
      domicilio: this.state.domicilio_nuevo_deudor,
      ciudad: this.state.ciudad_nuevo_deudor,
      cp: this.state.cp_nuevo_deudor,
      telefono: this.state.telefono_nuevo_deudor,
      mail: this.state.mail_nuevo_deudor,
      expedient_id: this.state.expedientid,
    };
    axios
      .put(`${baseAPI}/expedients/adddebtor`, newDebtor)
      .then((res) => {
        console.log(res.data);
        window.location.reload();
      })
      .catch(function (error) {
        console.log(error);
        alert("Error al añadir la anotación");
      });
  }

  render() {
    return (
      <div>
        <div className="grid grid-cols-12 gap-6 w-full">
          {this.debtorsList()}
        </div>

        {this.state.crear_deudores === false && (
          <button
            className=" btn btn-primary btn-sm mt-6 bg-blue-600 hover:bg-blue-700 text-white font-bold rounded shadow-lg hover:shadow-xl transition duration-200"
            onClick={this.handleClick}
          >
            Añadir deudor
          </button>
        )}

        {this.state.crear_deudores === true && (
          <>
            <h3 className="mt-4">Añade un nuevo Deudor a continuación:</h3>
            <form
              method="POST"
              action="#"
              onSubmit={this.onSubmitCreateDebtors}
            >
              <div className="grid-cols-2 gap-6 mt-4 w-full">
                <div className=" px-3 mb-6 md:mb-0 mt-3 w-full">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1"
                    htmlFor="grid-first-name"
                  >
                    Nombre
                  </label>
                  <input
                    className=" rounded w-full text-gray-700 focus:outline-none border-b-1 border-gray-300 focus:border-blue-600 transition duration-500 px-3 pb-3"
                    id="grid-first-name"
                    type="text"
                    placeholder="Nombre y Apellidos"
                    onChange={this.onChangeNombreNuevoDeudor}
                  />
                </div>

                <div className=" px-3 mb-6 md:mb-0 mt-3 w-full">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1"
                    htmlFor="grid-first-name"
                  >
                    Domicilio
                  </label>
                  <input
                    className=" rounded w-full text-gray-700 focus:outline-none border-b-1 border-gray-300 focus:border-blue-600 transition duration-500 px-3 pb-3"
                    id="grid-first-name"
                    type="text"
                    placeholder="Calle, nº, piso"
                    onChange={this.onChangeDomicilioNuevoDeudor}
                  />
                </div>
                <div className=" px-3 mb-6 md:mb-0 mt-3 w-full">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1"
                    htmlFor="grid-first-name"
                  >
                    Ciudad
                  </label>
                  <input
                    className=" rounded w-full text-gray-700 focus:outline-none border-b-1 border-gray-300 focus:border-blue-600 transition duration-500 px-3 pb-3"
                    id="grid-first-name"
                    type="text"
                    placeholder="Ciudad"
                    onChange={this.onChangeCiudadNuevoDeudor}
                  />
                </div>

                <div className=" px-3 mb-6 md:mb-0 mt-3 w-full">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1"
                    htmlFor="grid-first-name"
                  >
                    Código Postal
                  </label>
                  <input
                    className=" rounded w-full text-gray-700 focus:outline-none border-b-1 border-gray-300 focus:border-blue-600 transition duration-500 px-3 pb-3"
                    id="grid-first-name"
                    type="text"
                    placeholder="Código Postal"
                    onChange={this.onChangeCPNuevoDeudor}
                  />
                </div>

                <div className=" px-3 mb-6 md:mb-0 mt-3 w-full">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1"
                    htmlFor="grid-first-name"
                  >
                    Teléfono
                  </label>
                  <input
                    className=" rounded w-full text-gray-700 focus:outline-none border-b-1 border-gray-300 focus:border-blue-600 transition duration-500 px-3 pb-3"
                    id="grid-first-name"
                    type="text"
                    placeholder="Teléfono"
                    onChange={this.onChangeTelefonoNuevoDeudor}
                  />
                </div>

                <div className=" px-3 mb-6 md:mb-0 mt-3 w-full">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1"
                    htmlFor="grid-first-name"
                  >
                    Email
                  </label>
                  <input
                    className=" rounded w-full text-gray-700 focus:outline-none border-b-1 border-gray-300 focus:border-blue-600 transition duration-500 px-3 pb-3"
                    id="grid-first-name"
                    type="text"
                    placeholder="Email"
                    onChange={this.onChangeMailNuevoDeudor}
                  />
                </div>
              </div>
              <button
                className=" btn btn-primary btn-sm mt-6 bg-blue-600 hover:bg-blue-700 text-white font-bold rounded shadow-lg hover:shadow-xl transition duration-200"
                type="submit"
              >
                Añadir nuevo deudor
              </button>
            </form>
          </>
        )}
      </div>
    );
  }
}
